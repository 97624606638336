import { Injectable } from '@angular/core';
import { reject } from 'q';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../core/base.service';
import { StorageService } from '../core/storage.service';
import { ForgotPasswordAPIParam, ForgotPasswordAPIResponse, LoginAPiResponse } from '../core/models/forgot-pwd-model';
import { AutoRefreshApiResponse, AutoRefreshPreference, LoginApiParams, TokenApiResponse } from '../core/models/login-model';
import { AdamConf } from '@app/app.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _currentUserSubject: BehaviorSubject<TokenApiResponse>;
  public currentUser: Observable<TokenApiResponse>;

  private _userPermissionsSubject: BehaviorSubject<any>;
  public userPermissions$: Observable<any>;

  private _menuItemsSubject: BehaviorSubject<any>;
  public menuList$: BehaviorSubject<any>;
  public menuItems$: Observable<any>;

  private _notificationSubject: BehaviorSubject<any>;
  public notification: Observable<any>;

  constructor(private baseService: BaseService, private storageService: StorageService) {
    this._currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.storageService.getItem('currentUser')));
    this.currentUser = this._currentUserSubject.asObservable();

    this._userPermissionsSubject = new BehaviorSubject<any>(JSON.parse(this.storageService.getItem('userData')));
    this.userPermissions$ = this._userPermissionsSubject.asObservable();

    this._menuItemsSubject = new BehaviorSubject<any>(JSON.parse(this.storageService.getItem('menuData')));
    this.menuList$ = new BehaviorSubject<any>({});
    this.setNavMenu(JSON.parse(this.storageService.getItem('menuData')));
    this.menuItems$ = this._menuItemsSubject.asObservable();

    this._notificationSubject = new BehaviorSubject<any>('');
    this.notification = this._notificationSubject.asObservable();
  }

  getCurrentUser() {
    return this._currentUserSubject.value;
  }

  logout(email = null, token = null): Promise<boolean> {
    // remove user from local storage to log user out
    return new Promise(resolve => {
      if (!email) {
        const userSession = this.getCurrentUser();
        if (userSession) {
          email = userSession['email'];
          token = userSession['access_token'];
        }
      }
      const param = {
        email: email,
        token: token
      };
      const userHeaders = {
        'user-action': 'Logout',
        'user-navigation': '/logout'
      }
      this.baseService.postViaObjectParam('user-management/api/v1/public/authenticate/logout', param, userHeaders).subscribe(
        resp => {
          this.resetSession();
          resolve(true);
        },
        error => {
          this.resetSession();
          resolve(true);
        }
      );
    });
  }

  logoutusersetup(email) {
    return new Promise(resolve => {
      const param = {
        email: email
      };
      this.baseService.postViaObjectParam('user-management/api/v1/public/authenticate/logout', param).subscribe(
        resp => {
          this.handleLogOutUserSetupResult(resolve);
        },
        error => {
          this.handleLogOutUserSetupResult(resolve);
        }
      );
    });
  }

  handleLogOutUserSetupResult(resolve) {
    this.sessionRemoveItem();
    this._currentUserSubject.next(null);
    resolve(true);
  }

  sessionRemoveItem() {
    this.storageService.removeItem('currentUser');
    this.storageService.removeItem('userData');
    this.storageService.removeItem('menuData');
    this.storageService.removeItem('expTime');
  }
  resetSession() {
    this.sessionRemoveItem();
    this.storageService.removeSessionItem('notificationCount');
    this.storageService.removeSessionItem('notifications');
    this._currentUserSubject.next(null);
  }

  setUserPermissions(data) {
    this.storageService.setItem('userData', data);
    this._userPermissionsSubject.next(JSON.parse(data));
  }

  setMenuItems(data) {
    this.storageService.setItem('menuData', data);
    this._menuItemsSubject.next(JSON.parse(data));
    this.setNavMenu(JSON.parse(data));
  }

  setNavMenu(data) {
    const excludeList = AdamConf.MENU_EXCLUSIONS;
    if (data) {
      data.features = data.features.filter(element => !excludeList.includes(element.menuName.toLowerCase()));
    }
    this.menuList$.next(data);
  }

  getUserData() {
    return this.getCurrentUser();
  }

  setUserPermissionData(): Promise<boolean> {
    return new Promise(resolve => {
      let userData = {};
      let menuData = {};
      this.baseService.getViaParam('user-management/api/v1/users/permissiontype/features', null).subscribe(data => {
        userData = this.processData(data);
        menuData = data;
        this.setUserPermissions(JSON.stringify(userData));
        this.setMenuItems(JSON.stringify(menuData));
        this.setUserHomeData(menuData);
        resolve(true);
      }, error => {
        reject(false);
      });
    });
  }

  setUserHomeData(menuData) {
    if (menuData) {
      const homePage = menuData.homePage;
      const tabPanel = menuData.features.find(x => x.menuName.toUpperCase() === 'TABPANEL');
      if (tabPanel) {
        const tabPanelEntries = tabPanel.subMenus[0].subMenuNames;
        const userHome = tabPanelEntries.find(x => x.subMenuName.toUpperCase() === homePage.toUpperCase());
        const userHomeData = { userHomeLabel: homePage, userHomeLink: userHome.link };
        this.storageService.setItem('userHome', JSON.stringify(userHomeData));
      }
    }
  }

  processData(data) {
    let roleData = {};
    if (data.features) {
      data.features.forEach(element => {
        roleData[element.menuName] = [].concat.apply([], this.getMenuNames(element.subMenus));
      });
    }
    return roleData;
  }

  getMenuNames(data) {
    return data.map(element => {
      return element.subMenuNames.map(subMenuName => {
        return subMenuName.subMenuName;
      });
    });
  }

  login(param: LoginApiParams): Observable<LoginAPiResponse> {
    const userAction = 'Login';
    return this.baseService.postViaObjectParam('user-management/api/v1/public/authenticate/login', param, {'user-action': userAction});
  }

  setSession(value: TokenApiResponse): Promise<boolean> {
    return new Promise(resolve => {
      this.storageService.setItem('currentUser', JSON.stringify(value));
      this.storageService.setItem('institutionId', value.institutionId.toString());
      this.storageService.setItem('facilityId', value.facilityId.toString());
      this.setExpiry(value['expires_in']);
      this._currentUserSubject.next(value);
      resolve(true);
    });
  }

  public getAutoRefreshPreferences(): Observable<AutoRefreshApiResponse> {
    return this.baseService.getViaParam('user-management/api/v1/users/auto-refresh', null);
  }

  setSessionValues(value: TokenApiResponse): void {
    this.storageService.setItem('currentUser', JSON.stringify(value));
    this.storageService.setItem('institutionId', value.institutionId.toString());
    this.storageService.setItem('facilityId', value.facilityId.toString());
    this.setExpiry(value['expires_in']);
    this._currentUserSubject.next(value);
  }

  updateUserDatePreference(newDatePreference: string) {
    let currentUser = JSON.parse(this.storageService.getItem('currentUser'));
    currentUser.datePreference = newDatePreference;
    this.storageService.setItem('currentUser', JSON.stringify(currentUser));
    this._currentUserSubject.next(currentUser);
  }

  public updateAutoRefreshPreferenceInSession(newValue: boolean): void {
    const currentUser = JSON.parse(this.storageService.getItem('currentUser'));
    currentUser.isAutoRefreshEnabled = newValue;
    this.storageService.setItem('currentUser', JSON.stringify(currentUser));
    this._currentUserSubject.next(currentUser);
  }

  public addAutoRefreshPreferenceInSession(preference: AutoRefreshPreference): void {
    const oneMinuteInMilliseconds = 60000;
    const currentUser = JSON.parse(this.storageService.getItem('currentUser'));
    currentUser.isAutoRefreshEnabled = preference.isAutoRefreshEnabled;
    currentUser.autoRefreshTimeInterval = preference.autoRefreshTimeInterval * oneMinuteInMilliseconds;
    this.storageService.setItem('currentUser', JSON.stringify(currentUser));
    this._currentUserSubject.next(currentUser);
  }

  getLatestUserData() {
    return JSON.parse(this.storageService.getItem('currentUser'));
  }

  initiateNotificationInfo() {
    this._notificationSubject.next('Update');
  }
  initiateNotificationInfoOnLogin() {
    this._notificationSubject.next('loginUpdate');
  }

  forgotPassword(params: ForgotPasswordAPIParam): Observable<ForgotPasswordAPIResponse> {
    return this.baseService.postViaObjectParam('user-management/api/v1/public/authenticate/forgotpassword', params);
  }

  changePassword(params) {
    return this.baseService.postViaObjectParam('user-management/api/v1/authenticate/changePassword', params);
  }

  setExpiry(time) {
    const curTime = new Date();
    const upTime = curTime.setSeconds(curTime.getSeconds() + time);
    this.storageService.setItem('expTime', upTime.toString());
  }

  getExpiry() {
    const expiryTime = this.storageService.getItem('expTime');
    if (expiryTime) {
      return expiryTime;
    } else {
      return null;
    }
  }

  getRefreshToken() {
    const userSession = this.getCurrentUser();
    const params = {
      grant_type: 'refresh_token',
      refresh_token: userSession['refresh_token']
    };
    const userAction = 'Login';
    return this.baseService.postViaHttpParam('user-management/api/v1/oauth/token', params);
  }

  autherizeUser(params) {
    const userAction = 'Login';
    return this.baseService.postViaHttpParam('user-management/api/v1/oauth/token', params);
  }

  signUp(params): Observable<LoginAPiResponse> {
    return this.baseService.postViaObjectParam('user-management/api/v1/public/authenticate/signup', params);
  }

  resetPassword(params): Observable<ForgotPasswordAPIResponse> {
    return this.baseService.postViaObjectParam('user-management/api/v1/public/authenticate/resetPassword', params);
  }

  expiredPwdChange(params): Observable<ForgotPasswordAPIResponse> {
    return this.baseService.postViaObjectParam('user-management/api/v1/public/authenticate/changePassword', params);
  }

}
